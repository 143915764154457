'use client'

import { ButtonIcon } from '~/scalis-components/core/button-icon'
import { FileItemProps } from './file-item.types'

export const FileItem = ({ name, handleRemove }: FileItemProps) => {
  return (
    <div className='flex w-full items-center justify-between rounded-xl border border-neutral-10 p-3'>
      <div className='flex items-center gap-1 min-w-0'>
        <i className='fa-regular fa-file w-4 flex-shrink-0 text-brand-primary-rest' />
        <span className='truncate text-neutral-primary'>{name}</span>
      </div>
      <div className='ml-2 flex-shrink-0 flex justify-end gap-x-2'>
        <ButtonIcon
          icon='fa-regular fa-xmark'
          onClick={handleRemove}
          aria-label='Remove file'
        />
      </div>
    </div>
  )
}
