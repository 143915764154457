import { useEffect, useState } from 'react'

const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const withDelay = (callback: () => void, delay?: number) => {
  let timeoutId: NodeJS.Timeout | undefined = undefined
  if (timeoutId) clearTimeout(timeoutId)
  timeoutId = setTimeout(callback, delay || 1)
  return timeoutId
}

export default useDebounce
