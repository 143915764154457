'use client'

import { useRef, useState } from 'react'
import { cn } from '~/utils/cn'
import { Button, ButtonKind, ButtonWidth } from '../../button'
import { Icon } from '../../icon'
import { FileItem } from './components/file-item'
import { FileInputProps } from './file-input.types'

interface DragAndDropProps {
  isDragActive: boolean
  dragMessage?: string
  dragActiveMessage?: string
}

export const FileInput = ({
  accept,
  name,
  isMultiple,
  label,
  onFileChange,
  onFileRemoval,
  error: formError,
  children,
  buttonWidth = ButtonWidth.full,
  isStandalone = false,
  kind,
  showFileNames = true,
  isLoading,
  disable,
  defaultFiles,
  dragAndDrop,
  hideOnUpload = false,
}: FileInputProps) => {
  const [files, setFiles] = useState<File[]>(defaultFiles ?? [])
  const [error, setError] = useState<string>('')
  const [isDragging, setIsDragging] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dropZoneRef = useRef<HTMLDivElement>(null)

  const handleFileChange = (newFiles: FileList) => {
    const fileArray = Array.from(newFiles)

    try {
      onFileChange?.(newFiles)
      isMultiple ? setFiles((prev) => [...prev, ...fileArray]) : setFiles(fileArray)
    } catch (e) {
      setError('Please try again, something wrong happened!')
    }
  }

  const handleRemove = (index: number) => {
    const filteredFiles = files.filter((_, i) => i !== index)
    setFiles(filteredFiles)
    onFileRemoval?.(filteredFiles)
  }

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (!disable) {
      setIsDragging(true)
    }
  }

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)

    if (disable) return

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFileChange(e.dataTransfer.files)
    }
  }

  const errorMessage = formError || error

  return (
    <div className='flex flex-col'>
      {dragAndDrop && (!hideOnUpload || (hideOnUpload && files.length === 0)) ? (
        <>
          <div
            ref={dropZoneRef}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={cn(
              'relative rounded-xl border border-dashed p-8 transition-colors',
              isDragging ? 'border-brand-primary-rest bg-brand-primary-light' : 'border-neutral-10',
              disable && 'cursor-not-allowed opacity-50',
              files.length > 0 && 'mb-4',
            )}
          >
            <div className='flex flex-col items-center justify-center gap-4'>
              <Icon
                icon='fa-regular fa-cloud-arrow-up fa-2xl'
                className={cn('p-4', isDragging ? 'text-brand-primary-rest' : 'text-neutral-secondary')}
              />
              <p className='text-center text-neutral-secondary text-sm'>
                {isDragging
                  ? dragAndDrop.dragActiveMessage || 'Drop files here...'
                  : dragAndDrop.dragMessage || 'Drag and drop files here, or'}
              </p>
              <input
                type='file'
                name={name}
                accept={accept}
                multiple={isMultiple}
                className='hidden'
                id='fileInput'
                data-testid='file-input'
                onChange={(event) => {
                  if (event.target.files) {
                    handleFileChange(event.target.files)
                    if (fileInputRef.current) {
                      fileInputRef.current.value = ''
                    }
                  }
                }}
                ref={fileInputRef}
                disabled={disable}
              />
              <Button
                kind={ButtonKind.tertiary}
                type='button'
                onClick={() => fileInputRef.current?.click()}
                disabled={disable}
              >
                <span className='flex items-center gap-2 px-4'>
                  Browse files...
                  <Icon
                    icon='fa-regular fa-upload'
                    className='size-4'
                  />
                </span>
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div>
          <input
            type='file'
            name={name}
            accept={accept}
            multiple={isMultiple}
            className='hidden'
            id='fileInput'
            data-testid='file-input'
            onChange={(event) => {
              if (event.target.files) {
                handleFileChange(event.target.files)
                if (fileInputRef.current) {
                  fileInputRef.current.value = ''
                }
              }
            }}
            ref={fileInputRef}
            disabled={disable}
          />

          {(!showFileNames || (showFileNames && !files?.length)) &&
            (children ?? (
              <Button
                {...(isStandalone ? { isStandalone: true } : { kind: kind ? kind : ButtonKind.tertiary })}
                className={cn('relative', buttonWidth === ButtonWidth.full && 'w-full')}
                width={buttonWidth}
                type='button'
                asChild
                loading={isLoading}
                disabled={disable}
                onClick={() => {
                  fileInputRef.current?.click()
                }}
              >
                <span className='flex w-full cursor-pointer items-baseline justify-center gap-2'>
                  {label}
                  <Icon icon='fa-regular fa-upload' />
                </span>
              </Button>
            ))}
        </div>
      )}
      <div className='flex flex-col gap-2'>
        {showFileNames &&
          files?.map?.(({ name }, index) => (
            <FileItem
              key={name}
              name={name}
              handleRemove={() => handleRemove(index)}
            />
          ))}
      </div>
      {errorMessage && (
        <div
          className='mt-6 text-left text-xs text-error'
          hidden={!errorMessage}
        >
          {errorMessage}
        </div>
      )}
    </div>
  )
}
